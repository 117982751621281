import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Save form data to session storage
export default class extends Controller {
  static targets = ["form"]

  timeout = null

  save(event) {
    event.preventDefault()
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      const formData = new FormData(this.formTarget)

      fetch("/contact_orders/autosave", {
        method: "POST",
        body: formData,
        headers: {
          "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
        }
      })
    }, 500)
  }
}
