function validateBusinessForm() {
    var currentURL = window.location.href;

    if (
      currentURL.includes("contacts") ||
      currentURL.includes("become-a-partner") ||
      currentURL.includes("/password/new") ||
      currentURL.includes("orders/new") ||
      currentURL.includes("boutiques") ||
      currentURL.includes("account")
    ) {
        function validateEmail(email) {
            const  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        }

        function validateUrl(url) {
            const urlRegex = /^(ftp|http|https):\/\/(www\.)?[^ "]+$/;
            const wwwRegex = /^(www\.)[^ "]+$/;

            return url === '' || urlRegex.test(url) || wwwRegex.test(url);
        }

        function validateTel(number) {
            const  numberRegex = /^\+?\d{7,}$/;
            return numberRegex.test(number);
        }

        function sanitizeInput(input) {
            return input.replace(/[^\d+]/g, '');
        }

        function setupInputValidation(inputs, validationFunction, sanitizeFunction, notificationText) {
            inputs.forEach(function (input) {
                input.addEventListener('input', function () {
                    const inputValue       = input.value;
                    const sanitizedValue   = sanitizeFunction ? sanitizeFunction(inputValue) : inputValue;
                    input.value            = sanitizedValue;

                    const isValid          = validationFunction(sanitizedValue);
                    input.classList.toggle('valid', isValid);

                    const notificationId   = `${input.id}-notification`;
                    const prevNotification = document.getElementById(notificationId);

                    if (prevNotification) {
                        prevNotification.remove();
                        if (document.querySelector('.how-use-text')) {
                            document.querySelector('.how-use-text').style.opacity = "1";
                        }
                    }

                    const notificationSpan = document.createElement('span');
                    notificationSpan.id    = notificationId;
                    notificationSpan.classList.add('notification');
                    notificationSpan.textContent = isValid ? '' : notificationText;
                    input.parentNode.appendChild(notificationSpan);
                });

                input.addEventListener('blur', function () {
                    const inputValue       = input.value;
                    const isValid          = validationFunction(inputValue);

                    const notificationId   = `${input.id}-notification`;
                    const prevNotification = document.getElementById(notificationId);
                    if (prevNotification) {
                        prevNotification.remove();
                    }

                    const notificationSpan = document.createElement('span');
                    notificationSpan.id    = notificationId;
                    notificationSpan.classList.add('notification');
                    notificationSpan.textContent = isValid ? '' : notificationText;
                    input.parentNode.appendChild(notificationSpan);
                });

                if (input.type === 'text' && input.hasAttribute('required')) {
                    input.addEventListener('blur', function () {
                        const inputValue       = input.value;
                        const isValid          = inputValue.trim() !== '';

                        const notificationId   = `${input.id}-notification`;
                        const prevNotification = document.getElementById(notificationId);
                        if (prevNotification) {
                            prevNotification.remove();
                        }

                        const notificationSpan = document.createElement('span');
                        notificationSpan.id    = notificationId;
                        notificationSpan.classList.add('notification');

                        var currentURL = window.location.href;
                        if (currentURL.includes("/en")) {
                            notificationSpan.textContent = isValid ? '' : 'This field is mandatory.';
                        } if (currentURL.includes("/ua")) {
                            notificationSpan.textContent = isValid ? '' : 'Це поле обов\'язкове для заповнення.';
                        } if (currentURL.includes("/ru")) {
                            notificationSpan.textContent = isValid ? '' : 'Это поле обязательно для заполнения.';
                        } if (currentURL.includes("/de")) {
                            notificationSpan.textContent = isValid ? '' : 'Das Feld ist obligatorisch.';
                        } if (currentURL.includes("/pl")) {
                            notificationSpan.textContent = isValid ? '' : 'To pole jest obowiązkowe.';
                        }
                        input.parentNode.appendChild(notificationSpan);
                    });
                }
            });
        }

        function validateAllTextInputs(inputs) {
            inputs.forEach(function (input) {
                input.addEventListener('input', function () {
                    const inputValue = input.value.trim();
                    const isValid    = !input.hasAttribute('required') || inputValue !== '';
                    input.classList.toggle('valid', isValid);
                });

                if (!input.hasAttribute('required')) {
                    input.classList.add('valid');
                }
            });
        }

        function validateCheckbox(input) {
            return input.checked;
        }

        function setupCheckboxValidation(checkboxes, validationFunction) {
            checkboxes.forEach(function (checkbox) {
                checkbox.addEventListener('change', function () {
                    const isValid = validationFunction(checkbox);
                    checkbox.classList.toggle('valid', isValid);
                });
            });
        }

        document.addEventListener('DOMContentLoaded', function () {
            const textInputs     = document.querySelectorAll('input[type="text"], textarea');
            const emailInputs    = document.querySelectorAll('input[type="email"]');
            const urlInputs      = document.querySelectorAll('input[type="url"]');
            const numberInputs   = document.querySelectorAll('.number-input');
            const checkboxes     = document.querySelectorAll('input[type="checkbox"]');

            var currentURL       = window.location.href;
            var languageMessages = {
                "en": {
                    text: 'Fill in this field correctly.',
                    email: 'Please enter a valid email address.',
                    url: 'Please enter a valid URL.',
                    number: 'Enter a valid phone number.'
                },
                "ua": {
                    text: 'Коректно заповніть дане поле.',
                    email: 'Введіть коректну електронну адресу.',
                    url: 'Введіть коректний URL.',
                    number: 'Введіть коректний номер телефону.'
                },
                "ru": {
                    text: 'Корректно заполните данное поле.',
                    email: 'Введите корректный адрес электронной почты.',
                    url: 'Введите корректный URL.',
                    number: 'Введите корректный телефонный номер.'
                },
                "de": {
                    text: 'Füllen Sie dieses Feld korrekt aus.',
                    email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
                    url: 'Bitte geben Sie eine gültige URL ein.',
                    number: 'Geben Sie eine gültige Telefonnummer ein.'
                },
                "pl": {
                    text: 'Wypełnij to pole poprawnie.',
                    email: 'Proszę wpisać aktualny adres e-mail.',
                    url: 'Proszę podać poprawny adres URL.',
                    number: 'Wpisz prawidłowy numer telefonu.'
                }
            };

            var languageCode = currentURL.match(/\/(\w{2})\//);
            languageCodeIsValid = languageCode?.includes("en") ||
                                  languageCode?.includes("ua") ||
                                  languageCode?.includes("ru") ||
                                  languageCode?.includes("de") ||
                                  languageCode?.includes("pl");
            if (!languageCodeIsValid) {
              languageCode = null;
            }
            var messages = languageCode ? languageMessages[languageCode[1]] : languageMessages["en"];

            setupInputValidation(textInputs,   function (value) { return true; }, null, messages.text);
            setupInputValidation(emailInputs,  validateEmail, null, messages.email);
            setupInputValidation(urlInputs,    validateUrl, null, messages.url);
            setupInputValidation(numberInputs, function (value) { return validateTel(value); }, sanitizeInput, messages.number);

            setupCheckboxValidation(checkboxes, validateCheckbox);
            validateAllTextInputs(textInputs);
        });


        var forms = document.querySelectorAll('.validate');

        forms.forEach(function (form) {
            var inputs = form.querySelectorAll('.input, textarea');
            inputs = Array.from(inputs).filter(function (input) {
                return !input.classList.contains('time-input');
            });

            var submitButton = form.querySelector('.main-button.submit');

            var observer = new MutationObserver(function (mutationsList) {
                checkInputs();
            });

            var observerOptions = {
                attributes: true,
                attributeFilter: ['class'],
                subtree: true
            };

            inputs.forEach(function (input) {
                observer.observe(input, observerOptions);
            });

            function checkInputs() {
                var allValid = true;

                inputs.forEach(function (input) {
                    const isFieldValid = input.classList.contains('valid') || (input.value.trim() !== '' && isValidField(input));

                    if (!isFieldValid) {
                        allValid = false;
                        return;
                    }
                });

                if (allValid) {
                    submitButton.style.opacity = '1';
                    submitButton.style.pointerEvents = 'all';
                    submitButton.removeAttribute('disabled');
                } else {
                    submitButton.style.opacity = '0.4';
                    submitButton.style.pointerEvents = 'none';
                    // submitButton.setAttribute('disabled', 'disabled');
                }
            }

            function isValidField(input) {
                if (input.type === 'email') {
                    return validateEmail(input.value);
                }

                if (input.type === 'text') {
                    return input.value.trim() !== '';
                }
            }
            checkInputs();
        });
    }
}

function validateSignUpForm(){
    var currentURL = window.location.href;
    if (currentURL.includes("users/sign_in")) {
        var passwordInput = document.querySelector('.password');
        var showHideBtn = document.querySelector('.show-hide-btn');

        showHideBtn.addEventListener('click', function() {
            showHideBtn.classList.toggle('show-pass');
            if (showHideBtn.classList.contains('show-pass')) {
                passwordInput.type = "text";
            } else {
                passwordInput.type = "password";
            }
        })
    }
}

validateBusinessForm();
validateSignUpForm();

export { validateBusinessForm, validateSignUpForm };
